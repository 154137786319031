<template>
	<div class="component-item-produto product__item">
		<router-link :to="{ path: productUrl(produto) }" @click.native="linkItemProdutoClick" v-if="carregouProduto">
			<!-- Product Image -->
			<div class="container-foto static-mb-16" ref="containerFoto">
				<span class="container-selo-promocao" v-if="possuiPromocao"><img :src="srcImagemSeloPromocao" alt="" loading="lazy" /></span>
				<img width="200" height="200" :alt="productName" :src="srcImagem" loading="lazy" @contextmenu="handlerContextMenu($event)" />
			</div>

			<div class="container-infos">
				<div class="container-texto-descricao">
					<h3 class="line-clamp-3 head-regular color-grey-3c title">
						{{ productName }}
					</h3>

					<span class="subtitle head-regular color-grey-6a line-clamp-2">{{ productApplicationsText }}</span>
				</div>
				<Divisor />

				<ProductPriceRange v-if="possuiPrecoRange && !selecionouCidade && !isClone" :lowPrice="produto.preco_low" :highPrice="produto.preco_high" :quantity="produto.qtde_lojas" class="productpage__similarprice" />

				<ProductPriceClone
					v-if="Object.keys(checkPriority(produto)).length !== 0 && checkPriority(produto).estoque_disponivel > 0 && isClone"
					:finalPrice="checkPriority(produto).preco_final"
					:majorPrice="checkPriority(produto).preco_majorado"
					:discountPrice="checkPriority(produto).preco_desconto"
					:discount="checkPriority(produto).desconto"
					:geolocation="geolocation"
					:dealerUf="checkPriority(produto).uf"
					priceClass="font-16 head-bold"
					discountClass="font-12"
					:showParcelas="false"
					:vendidoPor="checkPriority(produto).vendido_por"
					class="list"
				/>

				<ProductPrice
					v-if="mostrarPreco && precoPor && Object.keys(checkPriority(produto)).length !== 0 && checkPriority(produto).estoque_disponivel > 0 && !isClone"
					:finalPrice="checkPriority(produto).preco_final"
					:majorPrice="checkPriority(produto).preco_majorado"
					:discountPrice="checkPriority(produto).preco_desconto"
					:discount="checkPriority(produto).desconto"
					:geolocation="geolocation"
					:dealerUf="checkPriority(produto).uf"
					:showParcelas="false"
					priceClass="font-16 head-bold"
					discountClass="font-12"
					:quantity="produto.qtde_lojas"
					:vendidoPor="checkPriority(produto).vendido_por"
					class="list"
				/>

				<div class="container-texto-sem-estoque mx-auto" v-if="solicitarCotacao && !isClone">
					<div class="text-center container-texto-produto-indisponivel font-16 head-regular grey400 static-mb-16">Produto temporariamente esgotado. Em breve estará disponível novamente.</div>
				</div>

				<div class="container-texto-sem-estoque mx-auto" v-if="Object.keys(checkPriority(produto)).length === 0 && isClone">
					<div class="text-center container-texto-produto-indisponivel font-16 head-regular grey400 static-mb-16">Produto temporariamente esgotado. Em breve estará disponível novamente.</div>
				</div>
			</div>
		</router-link>
	</div>
</template>

<script>
import productMixin from "@/mixins/product"
import { checkProductAvailability } from "@/utils/availability"
import { getDealershipsText } from "@/utils/product"
import { getProductUrl } from "@/utils/route"
import ProductPrice from "@/components/Product/ProductPrice"
import ProductPriceClone from "@/components/Product/ProductPriceClone"
import ProductPriceRange from "@/components/Product/ProductPriceRange"
import lodash from "lodash"
export default {
	name: "ComponentItemProduto",
	mixins: [productMixin],
	props: {
		config: {
			type: Object,
		},
		dados: {
			type: Object | Array,
			required: true,
		},
		gtmConfig: {
			type: Object,
		},
		position: {
			type: Number,
			default: false,
		},
		produtosSugeridos: {
			type: Boolean,
			default: false,
		},
		geolocation: {
			type: Object,
		},
	},
	components: {
		ProductPrice,
		ProductPriceClone,
		ProductPriceRange,
	},
	data() {
		return {
			produto: {},
			precoEstoque: {
				carregando: false,
				data: null,
			},
		}
	},
	computed: {
		isClone() {
			return this.$epecaHubB2c.configJson.IS_CLONE
		},
		price() {
			if (this.checkPriority(this.produto).uf === this.geolocation.uf) {
				return this.checkPriority(this.produto).preco_desconto
			}
			return this.checkPriority(this.produto).preco_majorado
		},
		productWithStock() {
			if (this.produto.qtde_lojas > 0) {
				return true
			}
			return false
		},
		possuiPrecoRange() {
			if (this.productWithStock) {
				return true
			}
		},
		selecionouCidade() {
			return this.$store.getters["selecionouCidade"]
		},
		availableStores() {
			return this.$store.getters["availableStores"]
		},
		solicitarCotacao() {
			if (!this.productWithStock) {
				return true
			}
			return false
		},
		configJson() {
			return this.$epecaHubB2c.configJson
		},
		defaultStoreId() {
			if (this.configJson.DEFAULT_STORE_ID) {
				return this.configJson.DEFAULT_STORE_ID.split(",")
			}
		},

		carregouProduto() {
			return Object.keys(this.produto).length > 0
		},
		desconto() {
			return this.checkPriority(this.produto).desconto
		},
		featuresJson() {
			let ret = {}

			if (this.$epecaHubB2c) {
				ret = this.$epecaHubB2c.featuresJson
			}

			return ret
		},

		mostrarPreco() {
			return this.$store.getters["mostrarPreco"]
		},
		possuiPromocao() {
			let ret = false

			if (this.produto.promocao === "1") {
				ret = true
			}

			return ret
		},
		precoDe() {
			let precoOriginal = this.checkPriority(this.produto) && this.checkPriority(this.produto).estoque_disponivel ? this.checkPriority(this.produto).preco : ""
			return precoOriginal
		},

		precoPor() {
			let precoFinal = this.produto && this.checkPriority(this.produto).estoque_disponivel ? this.checkPriority(this.produto).preco_final : ""
			return precoFinal
		},

		selecionouCidade() {
			return this.$store.getters["selecionouCidade"]
		},
		srcImagem() {
			const fotos = this.produto.fotos
			let ret = ""

			let fotos360 = fotos.fotos_360
			let fotosCrop = fotos.fotos_crop
			let fotosAvulsas = fotos.fotos_avulsas
			let imgUrl = `${this.$epecaHubB2c.configJson.CDN_IMAGES_RESIZE}/300x300/`

			if (fotos360.length > 0) {
				imgUrl += fotos360[0]
			} else if (fotosAvulsas.length > 0) {
				imgUrl += fotosAvulsas[0]
			} else if (fotosCrop.length > 0) {
				imgUrl += fotosCrop[0]
			} else {
				imgUrl = "/custom/epeca/assets/img/icone-sem-foto.svg"
			}

			ret = imgUrl

			return ret
		},
		srcImagemSeloPromocao() {
			return this.produto.promocao_selo
		},
	},
	methods: {
		handlerContextMenu(e) {
			e.preventDefault()
		},
		productUrl(product) {
			return getProductUrl(product)
		},
		getStockText(quantity) {
			return getDealershipsText(quantity)
		},
		checkPriority(product) {
			if (this.availableStores) {
				return checkProductAvailability(product, this.availableStores)
			} else {
				return checkProductAvailability(product, false)
			}
		},
		atualizarDadosProduto(dados) {
			const produto = lodash.cloneDeep(dados)

			this.produto = produto
		},
		linkItemProdutoClick(e) {
			const position = this.position

			let productPrice = this.checkPriority(this.produto).preco_desconto

			let itemDetails = {
				item_id: this.produto.id_produto_variacao,
				item_name: this.produto.descricao,
				coupon: "",
				discount: 0,
				item_brand: "",
				item_category: "",
				item_variant: "",
				price: productPrice,
				currency: "BRL",
				quantity: 1,
			}

			if (process.env.TOKEN_EPA) {
				let $epa = new window._epa({
					cKey: process.env.TOKEN_EPA,
				})

				$epa.send("select_item", {
					item_list_name: this.gtmConfig.listName,
					item_list_id: this.gtmConfig.listName,
					items: [itemDetails],
				})
			}

			this.$gtmHelpers.sendEvent.withProducts({
				event: "clickProduct",
				options: {
					position,
				},
				products: {
					product: this.produto,
				},
				pagePath: this.gtmConfig.pagePath,
				pageType: this.gtmConfig.pageType,
				listName: this.gtmConfig.listName,
			})
		},
	},
	mounted() {
		this.atualizarDadosProduto(this.dados)
	},
	watch: {
		dados(value, oldValue) {
			this.atualizarDadosProduto(value)
		},
	},
}
</script>

<style lang="scss" scoped>
.component-item-produto {
	.container-carregando-preco .container-carregando {
		background: linear-gradient(95deg, #efefef 0% 4%, #ddd 8% 12%, #efefef 16% 100%);
		background-size: 200% 100%;
		margin-bottom: 0.5em;
		min-height: 1em;

		animation-name: produtoPrecoContainerCarregando;
		animation-duration: 1.25s;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}

	@keyframes produtoPrecoContainerCarregando {
		0% {
			background-position: 0 0;
			opacity: 0;
		}
		50% {
			background-position: -50% 0;
			opacity: 1;
		}
		100% {
			background-position: -100% 0;
			opacity: 0;
		}
	}
}

.component-item-produto {
	display: flex;

	box-sizing: border-box;
	text-decoration: none;
	width: 100%;
	height: 100%;
}

.component-item-produto a {
	flex: 1;
	display: inline-flex;
	flex-direction: column;
}

.component-item-produto .container-foto {
	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	position: relative;
	text-align: center;
}

.component-item-produto .container-foto > img {
	max-height: 100%;
	max-width: 100%;
}

.component-item-produto .container-foto .container-selo-promocao {
	position: absolute;
	right: 0;
	top: 0;
	z-index: 2;
}

.component-item-produto .container-foto .container-selo-promocao img {
	height: 48px;
}

.component-item-produto .container-infos {
	display: flex;
	flex-direction: column;
	// justify-content: space-between;

	background-color: #f0f0f0;
	box-sizing: border-box;
	min-height: 10em;
	padding: 0.3em 0.6em 0.6em;
	padding-bottom: 0;
	height: 100%;
}

.component-item-produto .container-infos .container-sku-fabricante {
	color: #999;
	font-size: 0.7em;
	text-transform: uppercase;
}

.component-item-produto .container-infos .container-preco {
	color: #000;
	min-height: 91px;
}

.component-item-produto .container-infos .container-preco .container-tag-desconto {
	text-align: right;
}

.component-item-produto .container-infos .container-preco .container-tag-desconto span::before {
	border-left: 3px solid #000;
}

.component-item-produto .container-infos .container-preco .container-tag-desconto span {
	background-color: #fd0;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
	box-sizing: border-box;
	display: inline-block;
	color: #000;
	font-size: 0.95em;
	font-weight: bold;
	left: 1.6em;
	padding: 0.2em 0.8em;
	position: relative;
}

.component-item-produto .container-infos .container-preco .container-texto-preco-de {
	color: #000;
	letter-spacing: -0.012em;
	line-height: 160%;
	text-decoration: line-through;
}

.component-item-produto .container-infos .container-preco .container-texto-preco-por {
	line-height: 160%;
}

.component-item-produto .container-infos .container-preco .container-texto-preco-por {
	display: flex;
	align-items: center;
	.desconto {
		background: #d6d6d6;
		padding: 2px 6px;
		font-size: 0.9em;
		font-weight: 600;
		margin-left: 7px;
	}
}

.component-item-produto .container-preco .container-texto-sem-estoque div:first-child {
	font-size: 1.1em;
	font-weight: 600;
	letter-spacing: -0.015em;
	line-height: 110%;
}

.component-item-produto .container-preco .container-texto-sem-estoque div:last-child {
	font-size: 0.85em;
	line-height: 120%;
}

.component-item-produto .container-preco .container-texto-nao-disponivel {
	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 1.1em;
	letter-spacing: -0.025em;
	line-height: 120%;
	min-height: 62px;
	padding: 0.25em 1em;
	text-align: center;
}

.component-item-produto .container-preco .container-texto-nao-disponivel i {
	margin-right: 0.25em;
}

@keyframes botaoAdicionarAnimacao {
	0% {
		height: 10%;
		opacity: 0;
		width: 0;
	}
	50% {
		height: 50%;
		opacity: 0.3;
		width: 100%;
	}
	100% {
		height: 100%;
		opacity: 0;
		width: 100%;
	}
}

@media (max-width: 767px) {
	.component-item-produto .container-infos {
		background-color: #f0f0f0;
	}

	.component-item-produto .container-infos .container-preco {
		height: auto;
		padding-top: 0.5em;
	}

	.component-item-produto .container-infos .container-preco .container-tag-desconto span {
		font-size: 0.85em;
	}

	.component-item-produto .container-infos .container-preco .container-texto-preco-de {
		font-size: 0.72em;
		line-height: 120%;
		margin-top: 0;
	}

	.component-item-produto .container-infos .container-preco .container-texto-preco-por {
		line-height: 120%;
	}

	.component-item-produto .container-infos .container-preco .container-texto-preco-por span {
		font-size: 1em;
	}

	.component-item-produto .container-infos .container-preco .container-texto-preco-por span.valor-desconto-inline {
		font-size: 0.6em;
		font-weight: normal;
		margin-left: 0.25em;
	}

	.component-item-produto .container-preco .container-texto-nao-disponivel {
		font-size: 0.75em;
	}
}
.container-texto-sem-estoque {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}
.container-texto-produto-indisponivel {
	min-height: 91px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.title {
	font-size: 16px;
	line-height: 19px;
	word-break: break-word;

	min-height: 58px;
}
.subtitle {
	font-size: 16px;
	line-height: 20px;
	margin-top: 8px;
	min-height: 40px;
}
.container-texto-descricao {
	display: flex;
	flex-direction: column;
}
@media (max-width: 767px) {
	.subtitle {
		min-height: inherit;
	}
	.title {
		min-height: inherit;
	}
}
</style>
