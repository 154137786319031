<template>
	<ais-instant-search class="auto-suggest" v-if="searchClient" :search-client="searchClient" :index-name="`prod_main_products_query_suggestions`">
		<ais-configure :hitsPerPage="5" :analyticsTags="ruleContext" :ruleContexts="ruleContext" />
		<ais-index :index-name="algoliaIndexProducts" />
		<ais-autocomplete>
			<div class="w-full" slot-scope="{ indices, refine }">
				<vue-autosuggest
					class="autosuggest"
					v-model="query"
					:suggestions="indicesToSuggestions(indices)"
					:get-suggestion-value="getSuggestionValue"
					:should-render-suggestions="shouldRenderSuggestions"
					@selected="onSelected"
					@input="tryRefine(refine)"
					:input-props="{
						id: 'autosuggest__input',
						class: 'autosuggest__input inputsearch w-full',
						placeholder: 'Busque pela peça, moto ou código original',
					}"
				>
					<template :slot="`before-section-prod_main_products_query_suggestions`">
						<div class="products">
							<div class="products__title" v-if="querySize > 0 && !queryLoading">
								<h3 class="font-16 head-bold mb-2">Termos mais buscados</h3>
							</div>
							<div v-else>
								<span style="font-size: 14px" class="head-regular">Nenhum termo encontrado</span>
							</div>
						</div>
					</template>
					<template :slot="`before-section-${algoliaIndexProducts}`">
						<div class="products">
							<hr />
							<div class="products__title" v-if="querySize > 0 && !queryLoading">
								<h3 class="font-16 head-bold mb-2">Produtos</h3>
							</div>
							<div v-else>
								<span style="font-size: 14px" class="head-regular">Nenhum produto encontrado</span>
							</div>
						</div>
					</template>

					<template slot-scope="{ suggestion }">
						<div class="autosuggest__item font-16 head-regular mb-2" style="cursor: pointer" v-if="suggestion.name === algoliaIndexProducts">
							{{ suggestion.item.descricao }}
							{{ suggestion.item.codigo_original }}
						</div>
						<div class="autosuggest__item font-16 head-regular mb-2" style="cursor: pointer" v-if="suggestion.name === `prod_main_products_query_suggestions`">
							<img style="height: 16px" class="mr-2" src="/custom/loja/assets/media/icons/lupa-primaria.svg" /><ais-highlight attribute="query" :hit="suggestion.item" />
						</div>
					</template>
				</vue-autosuggest>
			</div>
		</ais-autocomplete>
	</ais-instant-search>
</template>

<script>
import { VueAutosuggest } from "vue-autosuggest"
import { getProductUrl } from "@/utils/route"
import algoliasearch from "algoliasearch/lite"
import { AisAutocomplete, AisIndex, AisInstantSearch, AisConfigure, AisHighlight } from "vue-instantsearch"
export default {
	components: {
		AisHighlight,
		AisConfigure,
		AisAutocomplete,
		AisIndex,
		AisInstantSearch,
		VueAutosuggest,
	},
	data() {
		return {
			query: "",
			querySize: 10,
			queryLoading: false,
			searchClient: null,
		}
	},
	props: {
		algoliaIndexSuggestions: {
			type: String,
		},
		algoliaIndexProducts: {
			type: String,
		},
	},
	mounted() {
		const algoliaClient = algoliasearch(this.$epecaHubB2c.configJson.ALGOLIA_APPLICATION_ID, this.$epecaHubB2c.configJson.ALGOLIA_API_KEY_READ_ONLY)
		this.searchClient = {
			...algoliaClient,
			search(requests) {
				if (requests.every(({ params }) => !params.query)) {
					return Promise.resolve({
						results: requests.map(() => ({
							hits: [],
							nbHits: 0,
							nbPages: 0,
							page: 0,
							processingTimeMS: 0,
							hitsPerPage: 0,
							exhaustiveNbHits: false,
							query: "",
							params: "",
						})),
					})
				}
				return algoliaClient.search(requests)
			},
		}
	},
	computed: {
		ruleContext() {
			if (process.env.RULE_CONTEXT) {
				return process.env.RULE_CONTEXT.split(", ")
			} else {
				return ""
			}
		},
	},
	methods: {
		onSelected(selectedValue) {
			if (selectedValue) {
				// Suggestion
				if (selectedValue.name === "prod_main_products_query_suggestions") {
					this.$emit("newSearch", selectedValue.item.query)
				}
				// Produtos
				if (selectedValue.name === this.algoliaIndexProducts) {
					let productUrl = this.productUrl(selectedValue.item)
					this.$nuxt.$router.push(`${productUrl}`)
				}
			} else {
				// Busca simples por texto
				this.$emit("newSearch", this.query)
			}
		},
		tryRefine(refine) {
			refine(this.query)
		},
		getSuggestionValue(suggestion) {
			if (suggestion) {
				return suggestion.item.descricao || suggestion.item.query
			}
		},
		shouldRenderSuggestions(size, loading) {
			this.querySize = size
			this.queryLoading = loading
			if (this.query !== "") {
				return size >= 0 && !loading
			}
		},
		productUrl(product) {
			return getProductUrl(product)
		},
		onSelect(selected) {
			if (selected) {
				if (selected.item.descricao) {
					let productUrl = this.productUrl(selected.item)
					this.$nuxt.$router.push(`${productUrl}`)
				}
				if (selected.item.query) {
					this.query = selected.item.query
					this.$emit("newSearch", this.query)
				}
			} else {
				this.$emit("newSearch", this.query)
			}
		},
		indicesToSuggestions(indices) {
			return indices.map(({ hits, indexName }) => ({
				data: hits,
				name: indexName,
			}))
		},
	},
}
</script>

<style lang="scss">
.header__vw {
	.autosuggest {
		background: transparent !important;
		border: none !important;
		width: 100%;
		height: 24px;
		padding: 0;
		position: relative;
		&__input {
			width: 100%;
			background-color: transparent;
			color: #fff;
			font-family: "VWHead-Light", sans-serif !important;
			font-size: 14px !important;
			// height: 32px;
			padding-left: 8px;
			&::placeholder {
				color: #fff;
				font-family: "VWHead-Light", sans-serif !important;
			}
		}
		&__item {
			height: 40px;
			border-radius: 3px;
			display: flex;
			align-items: center;
			cursor: pointer;
			padding: 0 1.5em;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 100%;
			// font-size: 16px;
			font-family: "VWHead-Light", sans-serif !important;
			font-size: 14px !important;
			@media (max-width: 767px) {
				display: block;
				line-height: 40px;
				img {
					display: none;
				}
			}
			&:hover {
				background-color: rgba(202, 207, 255, 0.2);
			}
			.ais-Highlight-highlighted {
				font-weight: bold;
				background: transparent !important;
			}
		}
		&__results {
			position: absolute;
			z-index: 99;
			background: #fff;
			box-shadow: 0 6px 12px 0 rgb(0 0 0 / 20%);
			width: 100%;
			left: 0;
			top: 30px;
			position: absolute;
			z-index: 10000001;
			padding-bottom: 1em;
			ul {
				li:first-child {
					padding-top: 1em;
				}
			}
		}
		.products {
			padding: 1em;
			padding-bottom: 8px !important;
			&__title {
				display: flex;
				align-items: center;
				&__text {
					font-weight: bold;
					color: #00498f;
					height: 40px;
					display: flex;
					align-items: center;
				}
				&__line {
					margin-left: 0.5em;
					border-bottom: 1px solid #00498f;
					flex: 1;
				}
			}
		}
	}
	.wrap-asg {
		display: flex;
		align-items: flex-end;
	}
}
.inputsearch {
	border: none;
	font-weight: 300;
	font-size: 16px;
	line-height: 20px;
	color: #3c484d;
	font-family: var(--head-regular);

	height: 53px;
	position: relative;
	&::placeholder {
		font-weight: 300;
		font-size: 16px;
		line-height: 20px;
		color: #3c484d;
		font-family: var(--head-regular);
	}
	@media (max-width: 767px) {
		font-size: 14px;
		line-height: 17px;
		&::placeholder {
			font-size: 14px;
			line-height: 17px;
		}
	}
}
.auto-suggest {
	width: 100%;
}
.autosuggest {
	width: 100%;
	position: relative;
}
.autosuggest__results {
	position: absolute;
	top: 72px;
	background: #fff;
	width: 100%;
	padding: 24px;
	z-index: 99;
	box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
}
.autosuggest__item {
	display: inline-flex;
	border-bottom: 1px solid transparent;
	color: #3c484d;
	align-items: center;
	&:hover {
		border-bottom: 1px solid #001e50;
	}
}
</style>
