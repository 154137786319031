<template>
	<div>
		<ais-instant-search :search-client="searchClient" :index-name="algoliaIndex">
			<ais-configure :hitsPerPage="99" :facetFilters="facetFilters" :distinct="false"></ais-configure>
			<div class="filter__wrapper">
				<div>
					<h1 v-html="title"></h1>
					<div class="filter-3-cols">
						<!-- col veiculos -->
						<div class="col-veiculos flex flex-col filter-left">
							<div class="veiculo-selecionado-box" v-if="categoriaNova && modeloNovo && anoNovo">
								<div class="veiculo-selecionado-text">
									<div class="filter-title inline-block w-full text-center">Você está pesquisando peças para:</div>
									<div class="filter-veiculo head-bold text-center mb-4 sm:mb-0">{{ categoriaNova.name }} - {{ modeloNovo.name }} - {{ anoNovo.ano }}</div>
								</div>
								<div>
									<ais-hits :class-names="{ 'ais-Hits': 'informacoes-carro' }">
										<div class="flex w-full justify-center">
											<div class="carro filter-image" :is="'nuxt-link'" :to="createVehicleUrl()">
												<div class="carro__img">
													<img v-if="anoNovo.imagem" :src="`/custom/loja/assets/media/veiculos/${anoNovo.imagem}`" />
													<img v-else :src="`/custom/loja/assets/media/veiculos/sem_foto.png`" />
												</div>
											</div>
										</div>
									</ais-hits>
								</div>
							</div>

							<div v-else class="filter-title inline-block w-full text-center">Pesquise peças para seu veículo</div>

							<div class="flex">
								<div class="filter-widgets" v-if="!anoNovo">
									<ais-state-results>
										<template>
											<ais-hits class="lista-veiculos" :class-names="{ 'ais-Hits': 'informacoes-carro grid gap-4 grid-cols-3' }">
												<template slot-scope="{ items }">
													<div class="select-vw__wrapper">
														<label class="select-vw__label"
															><select class="select-vw__select" v-model="categoriaNova">
																<option :value="null" selected>Categoria</option>
																<option v-for="(c, $c) in categorizacao(items)" :key="$c" :value="c">{{ c.name }}</option></select
															><span class="select-vw__iconwrapper"
																><span class="select-vw__icon"
																	><svg class="svg-arrow-down" aria-hidden="true" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
																		<path d="m1.984 6.19 9.465 9.893c.28.299.418.397.551.397.145 0 .31-.114.572-.392l9.443-9.898.962.918-9.439 9.893a2.108 2.108 0 0 1-1.538.81 2.083 2.083 0 0 1-1.517-.813l-9.46-9.889z"></path></svg></span></span
														></label>
														<div class="StyledErrorText-sc-1w6zs1t cZqTKB"><div aria-live="polite" aria-relevant="additions text"></div></div>
													</div>

													<div class="select-vw__wrapper">
														<label class="select-vw__label">
															<select class="select-vw__select" v-if="!categoriaNova" :disabled="!categoriaNova">
																<option :value="null" selected>Veículo</option>
															</select>
															<select v-else class="select-vw__select" v-model="modeloNovo">
																<option value="null" selected>Veículo</option>
																<option v-for="m in categoriaNova.modelos" :key="m.id" :value="m">{{ m.name }}</option>
															</select>
															<span class="select-vw__iconwrapper"
																><span class="select-vw__icon"
																	><svg class="svg-arrow-down" aria-hidden="true" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
																		<path d="m1.984 6.19 9.465 9.893c.28.299.418.397.551.397.145 0 .31-.114.572-.392l9.443-9.898.962.918-9.439 9.893a2.108 2.108 0 0 1-1.538.81 2.083 2.083 0 0 1-1.517-.813l-9.46-9.889z"></path></svg></span></span
														></label>
														<div class="StyledErrorText-sc-1w6zs1t cZqTKB"><div aria-live="polite" aria-relevant="additions text"></div></div>
													</div>

													<div class="select-vw__wrapper">
														<label class="select-vw__label">
															<select class="select-vw__select" v-if="!modeloNovo" :disabled="!modeloNovo">
																<option :value="null" selected>Ano</option>
															</select>
															<select v-else class="select-vw__select" v-model="anoNovo">
																<option :value="null" selected>Ano</option>
																<option v-for="(a, $a) in modeloNovo.anos" :key="$a" :value="a">{{ a.ano }}</option>
															</select>
															<span class="select-vw__iconwrapper"
																><span class="select-vw__icon"
																	><svg class="svg-arrow-down" aria-hidden="true" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
																		<path d="m1.984 6.19 9.465 9.893c.28.299.418.397.551.397.145 0 .31-.114.572-.392l9.443-9.898.962.918-9.439 9.893a2.108 2.108 0 0 1-1.538.81 2.083 2.083 0 0 1-1.517-.813l-9.46-9.889z"></path></svg></span></span
														></label>
														<div class="StyledErrorText-sc-1w6zs1t cZqTKB"><div aria-live="polite" aria-relevant="additions text"></div></div>
													</div>
												</template>
											</ais-hits>
										</template>
									</ais-state-results>
									<!-- 
								<div>
									<button class="link" v-if="anoNovo" @click="clearFilter()">
										<span>Limpar filtros</span>
									</button>
								</div> -->
								</div>
							</div>

							<!-- <div class="carro__wrapper">
							<div class="informacoes-carro">
								<div v-if="!anoNovo">
									<img class="no-car" :src="`/custom/loja/assets/media/veiculos/sem_foto.png`" />
								</div>

								<div v-else style="width: 100%; display: flex">
									<div class="carro filter-image" :is="'nuxt-link'" :to="createVehicleUrl()">
										<div class="carro__img">
											<img :src="`/custom/loja/assets/media/veiculos/${anoNovo.imagem}`" />
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="searchbox mb-4">
							<input class="inputsearch w-full" type="text" @keyup.enter="tryRefine(query)" v-model="query" placeholder="Digite nome / código da peça" />
							<div class="tooltip">
								<button @click="tryRefine(query)">
									<img :src="`/custom/loja/assets/media/icons/lupa-primaria.svg`" />
								</button>
								<span class="tooltiptext">Pesquisar</span>
							</div>
						</div>

						<div class="grid md:grid-cols-2 gap-4">
							<div class="hidden md:block">
								<button @click="clearFilter()" class="btn__primary--outline">Selecionar outro veículo</button>
							</div>

							<div>
								<button class="btn__primary" @click="tryRefine(query)">
									<img :class="clienteClass" :src="`/custom/loja/assets/media/icons/lupa-white.svg`" />
									Buscar
								</button>
							</div>
						</div> -->
						</div>

						<div class="col-busca">
							<div class="filter-title inline-block w-full text-center">ou faça uma busca direta</div>

							<div class="searchbox">
								<input class="inputsearch w-full" type="text" @keyup.enter="tryRefine(query)" v-model="query" placeholder="Digite nome / código da peça" />
								<div class="tooltip">
									<button @click="tryRefine(query)">
										<img :src="`/custom/loja/assets/media/icons/lupa-primaria.svg`" />
									</button>
									<span class="tooltiptext">Pesquisar</span>
								</div>
							</div>
						</div>

						<div class="col-categoria">
							<div>
								<div class="filter-title inline-block w-full mb-4 text-center">ou selecione uma categoria</div>
								<div class="categories-list">
									<div v-for="(cat, $c) in categories" :key="$c" class="category-btn" :is="'nuxt-link'" :to="createCategoryUrl(cat.url)">
										<img :class="clienteClass" :src="`/custom/loja/assets/media/icons/${cat.icon}.svg`" :alt="cat.label" />
										<div>{{ cat.label }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="lg:hidden mx-auto">
						<button class="btn__primary mb-4" @click="tryRefine(query)">
							<img :class="clienteClass" :src="`/custom/loja/assets/media/icons/lupa-white.svg`" />
							Buscar
						</button>
						<button @click="clearFilter()" class="link mx-auto text-center w-full">
							<span class="block text-center">Selecionar outro veículo</span>
						</button>
					</div>
				</div>

				<div class="flex gap-8 hidden lg:flex">
					<button @click="clearFilter()" class="btn__primary--outline">Selecionar outro veículo</button>
					<button class="btn__primary" @click="tryRefine(query)">
						<img :class="clienteClass" :src="`/custom/loja/assets/media/icons/lupa-white.svg`" />
						Buscar
					</button>
				</div>
			</div>
		</ais-instant-search>
	</div>
</template>

<script>
import { AisInstantSearch, AisConfigure, AisHits, AisRefinementList, AisClearRefinements, AisCurrentRefinements, AisStateResults } from "vue-instantsearch"
import groupBy from "lodash/groupBy"
import orderBy from "lodash/orderBy"
import algoliasearch from "algoliasearch/lite"
export default {
	components: {
		AisInstantSearch,
		AisStateResults,
		AisHits,
		AisConfigure,
		AisRefinementList,
		AisClearRefinements,
		AisCurrentRefinements,
	},
	computed: {
		facetFilters() {
			let filter = []
			let categoriaQuery = null
			if (Object.keys(this.categoria).length > 0) {
				categoriaQuery = [`categoria:${this.categoria}`]
				filter.push(categoriaQuery)
			} else {
				categoriaQuery = null
			}

			let modeloQuery = null

			if (Object.keys(this.modelo).length > 0) {
				modeloQuery = [`modelo:${this.modelo}`]
				filter.push(modeloQuery)
			}

			let anoQuery = null

			if (Object.keys(this.ano).length > 0) {
				anoQuery = [`ano:${this.ano}`]
				filter.push(anoQuery)
			}

			return filter
		},
		categories() {
			return this.$epecaHubB2c.configJson.HOME_FILTER_CATEGORIES
		},
		title() {
			return this.$epecaHubB2c.configJson.HOME_FILTER_TITLE
		},
		algoliaIndex() {
			return "home_aplicacoes"
		},
		getFilterText() {
			if (!this.anoNovo) {
				return "Selecione <b style='color:#3C484D;font-weight:400;'>seu veículo</b> abaixo para encontrar <b style='color:#3C484D;font-weight:400;'>peças compatíveis</b>."
			} else {
				return "Você está pesquisando peças para:"
			}
		},
		cliente() {
			return process.env.envName
		},
		clienteClass() {
			if (this.cliente === "KTM") {
				return "ktm"
			}
			return "default"
		},
		rotaListaPecas() {
			if (this.cliente === "VW") {
				return "pecas-vw"
			} else {
				return "pecas-ktm"
			}
		},
	},
	methods: {
		tryRefine(query) {
			this.$store.dispatch("setLastSearch", query)
			this.$router.push({ path: `${this.createVehicleUrl()}`, query: { query: query } })
		},
		categorizacao(hits) {
			const veiculosPorCategoria = groupBy(hits, function (n) {
				return n.categoria
			})

			let arrayFinal = []

			for (const veiculo in veiculosPorCategoria) {
				if (veiculosPorCategoria.hasOwnProperty(veiculo)) {
					let arrModelos = veiculosPorCategoria[veiculo]

					const modelosPorVeiculo = groupBy(arrModelos, function (n) {
						return n.modelo
					})

					let arrayModelos = []

					for (const modelo in modelosPorVeiculo) {
						if (modelosPorVeiculo.hasOwnProperty(modelo)) {
							arrayModelos.push({ id: modelo, name: modelo, anos: modelosPorVeiculo[modelo] })
						}
					}

					arrayModelos = orderBy(arrayModelos, ["name"], ["asc"])

					arrayFinal.push({ id: veiculo, name: veiculo, modelos: arrayModelos })
				}
			}

			return arrayFinal
		},
		createCategoryUrl(category) {
			let categoriaQueryString = ""
			if (this.categoriaNova) {
				let categoriaString = this.categoriaNova.name.toLowerCase()
				categoriaQueryString = `?veiculo=${categoriaString}`
			}

			let modeloQueryString = ""
			if (this.modeloNovo) {
				let modeloString = this.modeloNovo.name.toLowerCase()
				modeloQueryString = `&modelo=${modeloString}`
			}

			let anoQueryString = ""
			if (this.anoNovo) {
				let anoString = this.anoNovo.ano
				anoQueryString = `&ano=${anoString}`
			}

			return `/lista/${this.rotaListaPecas}/${category}${categoriaQueryString}${modeloQueryString}${anoQueryString}`
		},
		createVehicleUrl() {
			let categoriaQueryString = ""
			if (this.categoriaNova) {
				let categoriaString = this.categoriaNova.name.toLowerCase()
				categoriaQueryString = `?veiculo=${categoriaString}`
			}

			let modeloQueryString = ""
			if (this.modeloNovo) {
				let modeloString = this.modeloNovo.name.toLowerCase()
				modeloQueryString = `&modelo=${modeloString}`
			}

			let anoQueryString = ""
			if (this.anoNovo) {
				let anoString = this.anoNovo.ano
				anoQueryString = `&ano=${anoString}`
			}

			return `/lista/${categoriaQueryString}${modeloQueryString}${anoQueryString}`
		},
		clearFilter() {
			this.categoriaNova = null
			this.modeloNovo = null
			this.anoNovo = null
			window.scrollTo({ top: 0, behavior: "smooth" })
		},
	},
	mounted() {
		this.$store.dispatch("setLastSearch", this.query)
	},
	data() {
		return {
			searchClient: algoliasearch(this.$epecaHubB2c.configJson.ALGOLIA_APPLICATION_ID, this.$epecaHubB2c.configJson.ALGOLIA_API_KEY_READ_ONLY),
			categoria: {},
			modelo: {},
			ano: {},
			categoriaNova: null,
			modeloNovo: null,
			anoNovo: null,
			query: "",
		}
	},
}
</script>

<style scoped lang="scss">
.filter__wrapper {
	background: #ffffff;
	box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
	flex: none;
	order: 1;
	flex-grow: 0;
	padding: 16px 24px;
	display: flex;
	gap: 32px;
	display: grid;
	flex-direction: column;
	@media (min-width: 1220px) {
		background: #ffffff;
		box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
		flex: none;
		order: 1;
		flex-grow: 0;
		padding: 20px 24px;
		display: flex;
		height: 272px;
	}
}
h1 {
	color: #6a767d;
	margin: 0 0 16px 0;
	text-align: center;
	font-size: 24px;
	line-height: 29px;
	b {
		color: #3c484d;
	}
	@media (min-width: 1024px) {
		font-size: 32px;
		line-height: 39px;
		margin-bottom: 16px;
	}
}
.filter-title {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	align-items: center;
	color: #6a767d;
	font-family: var(--head-regular);
	display: inline-block;
	margin-bottom: 8px;
	b {
		font-weight: 400;
		color: #3c484d;
	}
	@media (min-width: 1220px) {
		font-size: 20px;
		line-height: 24px;
	}
}
.filter-widgets {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 16px;
}
.categories-list {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 8px;
	@media (min-width: 1220px) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
		gap: 4px;
	}
}
.category-btn {
	background: #ffffff;
	display: flex;
	text-align: center;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	font-size: 10px;
	line-height: 12px;
	color: #3c484d;
	gap: 8px;
	padding: 8px;
	width: 100%;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
	font-family: var(--head-bold);
	cursor: pointer;
	img {
		height: 24px;
		width: 24px;
	}
	img.default {
		filter: invert(98%) sepia(1%) saturate(7230%) hue-rotate(182deg) brightness(94%) contrast(72%);
	}
	&:hover {
		color: #001e50;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
		img.default {
			filter: invert(10%) sepia(33%) saturate(4855%) hue-rotate(207deg) brightness(92%) contrast(104%);
		}
		img.ktm {
			filter: invert(61%) sepia(89%) saturate(4740%) hue-rotate(0deg) brightness(100%) contrast(105%);
		}
		svg {
			path {
				fill: #001e50;
			}
		}
	}
	@media (min-width: 1220px) {
		background: #ffffff;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: 11px;
		line-height: 13px;
		color: #3c484d;
		gap: 8px;
		padding: 8px;
		width: 75px;
		height: 56px;
		cursor: pointer;
		box-shadow: none;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
		font-family: var(--text-regular);
		font-weight: 400;
		&:hover {
			color: #001e50;

			svg {
				path {
					fill: #001e50;
				}
			}
		}
	}
}
.filter-image {
	max-width: 330px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	@media (max-width: 1220px) {
		max-width: 100%;
	}
	img {
		max-width: 100%;
	}
}
.carro__img {
	cursor: pointer;
	margin: 0 auto;
	@media (min-width: 1220px) {
		img {
			max-width: 156px;
			max-height: 88px;
		}
	}
	&:hover {
		outline: 2px solid #001e50;
	}
}
.select-vw__wrapper {
	max-width: 100%;
	&:last-of-type {
		.select-vw__label {
			margin-bottom: 0;
		}
	}
}
.select-vw__label {
	position: relative;
	display: block;
	color: #000000;
	cursor: pointer;
}
.select-vw__select {
	appearance: none;
	height: 42px;
	width: 100%;
	padding: 8px 32px 8px 0px;
	border: 0px;
	border-radius: 0px;
	border-bottom: 2px solid var(--primary_color);
	color: inherit;
	background: transparent;
	font-size: 16px;
	font-family: var(--text-regular);
	outline: none;
	cursor: pointer;
}
.select-vw__empty {
	font-size: 12px;
	width: 100%;
	display: flex;
	transition: color 300ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	pointer-events: none;
	margin: 0px;
	padding: 0px;
	position: absolute;
	top: 0px;
}
.select-vw__iconwrapper {
	position: absolute;
	bottom: 8px;
	display: flex;
	width: 100%;
	-webkit-box-pack: end;
	justify-content: flex-end;
	height: 24px;
	pointer-events: none;
	transition: color 300ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	-webkit-box-align: center;
	align-items: center;
	white-space: nowrap;
}
.select-vw__icon {
	position: relative;
	flex: 0 0 auto;
	overflow: visible;
	text-align: center;
	transition: color 300ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	line-height: 0;
}
.select-vw__icon::before {
	content: "";
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	padding: 12px;
	border-radius: 50%;
}
.select-vw__label:focus-within .StyledIconWrapper-sc-jne68a::before {
	background-color: rgb(0, 119, 204);
	content: "";
	position: absolute;
	width: 42px;
	height: 42px;
	border-radius: 999px;
	transform: translateX(9px);
	box-shadow: rgb(0, 30, 80) 0px 0px 0px 3px inset;
}
.select-vw__label:focus-within .StyledIconWrapper-sc-jne68a {
	color: rgb(255, 255, 255);
}
.cZqTKB {
	display: flex;
}
.no-car {
	max-width: 316px;
}
.filter-veiculo {
	font-size: 20px;
	line-height: 24px;
	color: #3c484d;
}
.searchbox {
	display: flex;
	border-bottom: 2px solid var(--primary_color);
	align-items: center;
}
.inputsearch {
	border: none;
	font-weight: 300;
	font-size: 16px;
	line-height: 20px;
	color: #3c484d;
	font-family: var(--head-regular);

	height: 53px;
	position: relative;
	&::placeholder {
		font-weight: 300;
		font-size: 16px;
		line-height: 20px;
		color: #3c484d;
		font-family: var(--head-regular);
	}
	@media (max-width: 767px) {
		font-size: 14px;
		line-height: 17px;
		&::placeholder {
			font-size: 14px;
			line-height: 17px;
		}
	}
}
.tooltip {
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: #555;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	bottom: 125%;
	left: 50%;
	margin-left: -60px;
	opacity: 0;
	transition: opacity 0.3s;

	padding: 6px;
	color: #fff;
	letter-spacing: 0;
	font-size: 16px;
	line-height: 24px;
	font-weight: normal;
	display: inline-block;
	border-radius: 4px;
	background-color: var(--tooltip-bg-color);
	transition: opacity 300ms ease 0s;
	animation-duration: 0.4s;
	@media (max-width: 767px) {
		font-size: 14px;
		width: 92px;
	}
}

.tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}
.filter-3-cols {
	display: flex;
	flex-direction: column;
	@media (min-width: 1220px) {
		display: grid;
		grid-template-columns: 416px 312px 312px;
		gap: 64px;
	}
}
.col-veiculos {
	display: flex;
	justify-content: space-between;
	position: relative;
	margin-bottom: 16px;
	&:after {
		@media (min-width: 1220px) {
			content: "";
			display: block;
			background: #96a3a8;
			width: 2px;
			height: 100%;
			position: absolute;
			right: -32px;
			top: 0;
		}
	}
	@media (min-width: 1220px) {
		margin-bottom: 0;
	}
}
.col-busca {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	margin-bottom: 16px;
	&:after {
		@media (min-width: 1220px) {
			content: "";
			display: block;
			background: #96a3a8;
			width: 2px;
			height: 100%;
			position: absolute;
			right: -32px;
			top: 0;
		}
	}
	@media (min-width: 1220px) {
		margin-bottom: 0;
	}
}
.col-categoria {
	margin-bottom: 16px;
	@media (min-width: 1220px) {
		margin-bottom: 0;
	}
}
.carro__wrapper {
	width: 72px;
	margin-right: 32px;
}
.veiculo-selecionado-box {
	@media (min-width: 1220px) {
		display: flex;
		gap: 16px;
	}
}
.veiculo-selecionado-text {
	@media (min-width: 1220px) {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}
.informacoes-carro {
	display: flex;
	flex-direction: column;
	@media (min-width: 1220px) {
		display: grid;
	}
}
.svg-arrow-down {
	path {
		fill: var(--primary_color);
	}
}
</style>
